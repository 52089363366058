import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'
import maputilities from '@smarttransit/map-utilities'

import {
  createStRoute,
  deleteStRoute,
  getStRoute,
  getStRouteTemplate,
  searchOtpRoutes,
  updateStRoute
} from '@/services/st-routes-service'

import { genericApiRequests } from '@/utilities/axios-factory'

import {
  getBusStopData,
  getCoarseGeolocation,
  getRoute,
  getRouteByRouteIdList,
  googleGeocodeRequest
} from '@/services/maps-service'

import { getBusStopsForProfileRoute, getProfileRoute } from '@/services/transportation-profile-routes-service'
import { addAlert, getCachedRegions } from '@/utilities/helpers'
import { geocodeSearch, getBusStopsByRouteIdList, getStRouteBusStops } from '@/services/bus-stops-service'
import SocketInstance from '@/utilities/socket-instance'

let _cachedComponentData = false

export default {
  props: {
    signedInUser: Object,
    routeId: String,
    hasUserType: Function,
    setRoute: Function,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      name: 'routes-route',
      currentRoute: null,
      currentRouteOriginal: null,
      apiInProgress: false,
      currentRouteError: null,
      deleteApiInProgress: false,
      stMapApis: null,
      mapboxAccessToken: process.env.VUE_APP_MAPBOX_KEY,
      maptilerKey: process.env.VUE_APP_MAPTILER_KEY,
      markerOptions: { startMarkerClass: 'st-map-marker-start', endMarkerClass: 'st-map-marker-end' },
      offsetHeight: 0,
      busStopsContainerHeight: 0,
      routeBusStops: [],
      routeEditorLoaded: false,
      routeIdListFromMap: null,
      routeLineFromMap: null,
      routeLabelFromMap: null
    }
  },
  mounted () {
    if (this.$router?.currentRoute?.name !== this.name) return
    this.$nextTick(this.onResize)
    this.agencies = this.$store.getters.getAgencies

    this.unwatchRouteControlBar = this.$watch(() => this.$refs.routeControlBar, this.onResize)

    if (!this.$props.routeId || this.$props.routeId === '0') {
      this.currentRoute = this.$props.setRoute(getStRouteTemplate())
      this.currentRouteOriginal = _cloneDeep(this.currentRoute)
    } else {
      this.getRoute()
    }

    this.stMapApis = {
      genericApiRequests,
      getRouteByRouteIdList,
      getRoute,
      getBusStopsForProfileRoute,
      getTransportationProfileRoute: getProfileRoute,
      addAlert,
      getBusStops: getBusStopData,
      getCachedRegions,
      googleGeocodeRequest,
      getCoarseGeolocation,
      searchRoutes: searchOtpRoutes,
      geocodeRequest: (keywords, longlat) => geocodeSearch({ keywords, longlat })
    }
  },
  watch: {
    $route () {
      if (this.$router.currentRoute.name === 'route') {
        this.onResize()
        this.getRoute()
      }
    }
  },
  computed: {
    hasAdminPermissions () {
      return this.$props.hasUserType && this.$props.hasUserType('admin')
    }
  },
  methods: {
    onMapLoaded () {
      this.onResize()
    },
    onSocketInstance ({ routerId, onSearchResult, onRouteResult }) {
      return new SocketInstance({
        baseUrl: process.env.VUE_APP_MAP_API_URL,
        prefix: '/v1/socket',
        accessToken: this.$store.getters.getToken?.id,
        onUnAuthorized: () => {
          addAlert({ message: `Connection is not authorized, you may need to <a href="/?forward=${this.$route.fullPath}">sign in</a> again`, type: 'error', isModal: true })
        },
        subscriptions: {
          ['/routes/' + routerId + '/geocode/subscribe']: onSearchResult,
          ['/routes/' + routerId + '/subscribe']: onRouteResult
        }
      })
    },
    onRouteSelected ({ routeGeometry, routeIdList, from, to } = {}) {
      this.routeIdListFromMap = routeIdList || null
      this.routeLabelFromMap = [from, to].filter(Boolean).join(` ${maputilities.ROUTE_LABEL_CONNECTOR} `) // route ? maputilities.generateRouteIdListStartDestinationLabel(route.geometry ? route : route.legs) : null
      this.routeLineFromMap = routeGeometry
    },
    saveSelectedRouteFromMap () {
      this.currentRoute.routeIdList = this.routeIdListFromMap
      this.currentRoute.label = !this.currentRoute.label ? this.routeLabelFromMap : this.currentRoute.label
      this.currentRoute.routeLineData = this.routeLineFromMap
      this.currentRoute.distance = this.calculateRouteDistance(this.currentRoute.routeLineData)
      this.routeEditorLoaded = false

      if (this.currentRoute.routeIdList) {
        getBusStopsByRouteIdList(this.currentRoute.routeIdList)
          .then((busStops) => {
            this.routeBusStops = [].concat(busStops)
          })
          // .catch((err) => {
          //   console.error(err)
          //   addAlert({ message: err, type: 'error' })
          // })
      }
    },
    cancelSelectedRouteFromMap () {
      this.routeIdListFromMap = null
      this.routeLabelFromMap = null
      this.routeLineFromMap = null
      this.routeEditorLoaded = false
    },
    onResize () {
      const breadcrumbsHeight = 57
      const routeControlBarHeight = this.$refs?.routeControlBar?.clientHeight || 0
      const routeHeaderHeight = this.$refs?.routeHeader?.clientHeight || 0
      this.offsetHeight = routeControlBarHeight + routeHeaderHeight + this.$store.getters.appToolbarHeight + this.$store.getters.appFooterHeight + breadcrumbsHeight
      this.busStopsContainerHeight = window.innerHeight - (this.offsetHeight + 22 + 17) // subtract footer and sub header
    },
    loadFarePermutations () {
      if (!this.currentRoute.id) {
        return alert('Please create and save your route first')
      }

      this.$router.push({ name: 'routes-route-fare-permutations', params: { routeId: this.currentRoute.id } })
    },
    refreshView () {
      this.$router.go()
    },
    calculateRouteDistance (routeLineData) {
      let distance = 'n/a'

      if (routeLineData?.length) {
        let routeCoordinates

        if (typeof routeLineData[0] === 'string') {
          routeCoordinates = routeLineData.map((o) => maputilities.convertPolylineToCoordinates(o))
          routeCoordinates = [].concat(...routeCoordinates)
        }

        if (routeCoordinates?.length) {
          distance = `${maputilities.distanceTravelled(routeCoordinates, routeCoordinates[routeCoordinates.length - 1], routeCoordinates[0])} km`
        }
      }

      return distance
    },
    async getRoute () {
      if (_cachedComponentData) {
        this.currentRoute = _cachedComponentData
        return Promise.resolve(this.currentRoute)
      } else {
        this.currentRouteError = null

        try {
          this.apiInProgress = true

          const [result, busStops] = await Promise.all([
            getStRoute({ id: this.$props.routeId }),
            getStRouteBusStops(this.$props.routeId)
          ])

          this.currentRoute = this.$props.setRoute(result)
          this.currentRoute.distance = this.calculateRouteDistance(this.currentRoute.routeLineData)
          this.routeBusStops = [].concat(busStops)
          this.currentRouteOriginal = _cloneDeep(this.currentRoute)
        } catch (err) {
          this.currentRouteError = err.message
        } finally {
          this.apiInProgress = false
        }
      }
    },
    async save () {
      if (!this.currentRoute.label) {
        return alert('Please add a label')
      }

      if (!this.currentRoute.routeIdList) {
        return alert('Please set a route')
      }

      if (!this.currentRoute.agencyId) {
        return alert('Please select an agency ID')
      }

      try {
        this.apiInProgress = true
        let result = null

        if (this.currentRoute.id) {
          result = await updateStRoute({
            id: this.currentRoute.id,
            routeIdList: this.currentRoute.routeIdList,
            agencyId: this.currentRoute.agencyId,
            label: this.currentRoute.label,
            routeLine: this.currentRoute.routeLineData
          })
        } else {
          result = await createStRoute({
            routeIdList: this.currentRoute.routeIdList,
            agencyId: this.currentRoute.agencyId,
            label: this.currentRoute.label,
            routeLine: this.currentRoute.routeLineData
          })
        }

        this.currentRoute = this.$props.setRoute(result)
        this.currentRouteOriginal = _cloneDeep(this.currentRoute)
        addAlert({ message: 'Route saved', type: 'success' })

        if (!this.currentRoute.id) {
          this.$router.replace({ name: 'routes-route', params: { routeId: result.id } })
        }
      } catch (err) {
        addAlert({ message: err, type: 'error' })
      } finally {
        this.apiInProgress = false
      }
    },
    async deleteRoute () {
      if (!this.currentRoute.id) {
        alert('Please save at least once before deleting')
      }

      if (confirm('Are you sure you want to delete this route?')) {
        try {
          this.deleteApiInProgress = true
          await deleteStRoute(this.currentRoute.id)
          addAlert({ message: 'Route deleted', type: 'success' })
          this.$router.push({ name: 'routes' }, () => (this.$props.forceRootViewRefresh()))
        } catch (err) {
          addAlert({ message: err, type: 'error' })
        } finally {
          this.deleteApiInProgress = false
        }
      }
    },
    loadParentView () {
      if (!this.isDataEdited() || confirm('Discard changes made?')) {
        this.$router.push({ name: 'routes' }, () => (this.$props.forceRootViewRefresh()))
      }
    },
    isDataEdited () {
      return !_isEqual(this.currentRoute, this.currentRouteOriginal)
    }
  },
  beforeDestroy () {
    this.unwatchRouteControlBar && this.unwatchRouteControlBar()
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isDataEdited() || confirm('Discard changes made?')) {
      _cachedComponentData = null
      next()
    } else {
      _cachedComponentData = this.currentRoute
      next(false)
    }
  }
}
