<template>
  <div :class="name">
    <div v-if="$router.currentRoute.name === name" v-resize="onResize">
      <h1 class="text-xs-center" id="routeHeader" ref="routeHeader">Route</h1>
      <div v-if="!currentRouteError">
        <div id="routeControlBar" ref="routeControlBar" class="layout justify-space-between mb-2">
          <div>
            <v-btn color="primary mr-2" @click="loadFarePermutations" flat :disabled="apiInProgress || deleteApiInProgress">Fare Permutations</v-btn>
          </div>
          <div>
            <v-btn color="warning" @click="loadParentView" flat :disabled="apiInProgress">Cancel</v-btn>
            <v-btn class="ml-2" v-if="currentRoute && currentRoute.id" @click="routeEditorLoaded = true" :disabled="apiInProgress || deleteApiInProgress">Edit Route</v-btn>
            <v-btn color="primary" @click="save" class="ml-2" :disabled="apiInProgress || deleteApiInProgress || !isDataEdited()" :loading="apiInProgress">Save</v-btn>
            <v-btn v-show="currentRoute && currentRoute.id" @click="deleteRoute" class="ml-2" color="warning" :disabled="apiInProgress || deleteApiInProgress" :loading="deleteApiInProgress">Delete</v-btn>
          </div>
        </div>
        <v-layout row wrap v-if="currentRoute">
          <v-flex sm4 xs12 pa-2>
            <v-layout row align-center v-if="currentRoute && !currentRoute.id">
              <v-flex sm4 pa-2 class="text-xs-right">
                &nbsp;
              </v-flex>
              <v-flex sm8 pa-2>
                <v-btn @click="routeEditorLoaded = true" :disabled="apiInProgress || deleteApiInProgress">Set a Route</v-btn>
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm4 pa-2 class="text-xs-right">
                <strong>Label</strong>
              </v-flex>
              <v-flex sm8 pa-2>
                <v-text-field :hint="currentRoute.label === routeLabelFromMap ? null : routeLabelFromMap" v-model="currentRoute.label" placeholder="Label the route" :disabled="apiInProgress" />
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm4 pa-2 class="text-xs-right">
                <strong>Agency ID</strong>
              </v-flex>
              <v-flex sm8 pa-2>
                <v-select
                  outline
                  hide-details
                  dense
                  :menu-props="{overflowX: true}"
                  :items="agencies"
                  label="Agency ID"
                  v-model="currentRoute.agencyId"
                />
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm4 pa-2 class="text-xs-right">
                <strong>Distance</strong>
              </v-flex>
              <v-flex sm8 pa-2>
                {{currentRoute.distance}}
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm4 pa-2 class="text-xs-right">
                <strong>Route ID List</strong>
              </v-flex>
              <v-flex sm8 pa-2>
                <v-text-field
                  class="text-xs-right text-field--flat"
                  :value="currentRoute.routeIdList"
                  readonly
                  hide-details
                />
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm4 pa-2 class="text-xs-right">
                <strong>Last updated</strong>
              </v-flex>
              <v-flex sm8 pa-2>
                {{currentRoute.dateUpdatedLabel}}
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex sm4 pa-2 class="text-xs-right">
                <strong>Date created</strong>
              </v-flex>
              <v-flex sm8 pa-2>
                {{currentRoute.dateCreatedLabel}}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm2 xs0 pa-2>
            <div><strong>Bus Stops</strong></div>
            <div v-if="routeBusStops.length" :style="`height: ${busStopsContainerHeight ? busStopsContainerHeight + 'px' : 'auto'}; overflow: auto`">
              <div class="route-bus-stops" v-for="(routeBusStop, index) in routeBusStops" :key="routeBusStop.id">
                <v-divider v-if="index > 0"></v-divider>
                <div>
                  <div class="marker">
                    <v-icon color="accent" v-if="index === 0">fas fa-map-marker-alt</v-icon>
                    <v-icon v-if="index > 0 && index < routeBusStops.length - 1">fas fa-map-pin</v-icon>
                    <v-icon color="accent" v-if="index === routeBusStops.length - 1">fas fa-map-marker-check</v-icon>
                  </div>
                  <div>{{routeBusStop.name}}</div>
                </div>
              </div>
            </div>
            <div v-if="!routeBusStops.length">
              No available bus stops
            </div>
          </v-flex>
          <v-flex sm6 xs12 pa-2>
            <st-map
              v-if="stMapApis"
              container-id="currentRouteMap"
              :api="stMapApis"
              :marker-options="markerOptions"
              :route-geometry="currentRoute.routeLineData"
              :mapbox-access-token="mapboxAccessToken"
              :maptiler-key="maptilerKey"
              :height-offset="offsetHeight"
              :on-map-loaded="onMapLoaded"
              :route-id-list="currentRoute.routeIdListEdit">
            </st-map>
          </v-flex>
        </v-layout>
      </div>
      <v-alert :value="currentRouteError" type="error">
        <p>{{currentRouteError}}</p>
        <p><a href @click.stop="refreshView()">Retry</a> or <router-link :to="{ name: 'routes' }">Return to routes list</router-link></p>
      </v-alert>
      <v-dialog
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
          v-model="routeEditorLoaded">
        <v-card>
          <v-toolbar card dark color="primary" dense height="48">
            <img src="@/assets/logo-badge.png" style="height: 25px" alt=""/>
            <v-spacer/>
            <v-toolbar-title>Route Editor/Selector</v-toolbar-title>
            <v-spacer/>
            <v-toolbar-items>
              <v-btn color="warning" :disabled="!routeIdListFromMap || !hasAdminPermissions" @click="saveSelectedRouteFromMap">Set
                Route
              </v-btn> &nbsp;
              <v-btn dark flat @click="cancelSelectedRouteFromMap">Cancel</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <st-map
              v-if="routeEditorLoaded"
              container-id="stRouteMap"
              :mapbox-access-token="mapboxAccessToken"
              :maptiler-key="maptilerKey"
              :on-socket-instance="onSocketInstance"
              :marker-options="markerOptions"
              :route-geometry="currentRoute.routeLineData"
              :height-offset="48"
              sidebar
              topbar
              is-modal
              :route-id-list="currentRoute.routeIdList"
              :api="stMapApis"
              :on-route-selected="onRouteSelected">
          </st-map>
        </v-card>
      </v-dialog>
    </div>
    <router-view
      :route-id="routeId"
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser"
      :has-user-type="hasUserType">
    </router-view>
  </div>
</template>

<style scoped>
.route-bus-stops > div {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  flex-direction: row;
  justify-content: flex-start;

  .marker {
    margin-right: 10px;
  }
}
</style>

<script>
import src from './src'
export default src
</script>
